import { Component, Prop, Vue } from 'vue-property-decorator'
import MainMenuStructure from 'model/local/main-menu-structure'
import MainMenuChapter from './main-menu-chapter'
import MainMenuElement from './main-menu-element'

@Component({
  template: require('./main-menu.html'),
  components: { MainMenuChapter, MainMenuElement }
})
export default class MainMenu extends Vue {
  @Prop([MainMenuStructure])
  structure: MainMenuStructure

  public roleEnabled = {
    admin: false,
    manager: false,
    worker: false,
    analytic: false,
    methodist: false,
    construct: false
  }

  logout () {
    this.$emit('logout')
  }

  mounted () {
    this.hideInterfaceElementsByRoleString()
  }

  isVisible (title: string) {
    if (!title) {
      return true
    }
    switch (title) {
      case 'Aдминистрирование':
        return this.adminVisibility
      case 'Конструктор тестов':
        return this.constructorVisibility
      case 'Конструктор курсов':
        return this.adminVisibility
      case 'Аналитическая панель':
        return this.analyticVisibility // && !this.projectDisableCourses
      case 'Авторам тестов':
        return this.authorsVisibility
      case 'Моё обучение':
        return !this.projectDisableCourses
      default:
        return true
    }
  }

  get projectDisableCourses (): boolean {
    return Vue.localStorage.get('project.disableCourses') === 'true'
  }

  get analyticVisibility (): boolean {
    return (this.roleEnabled.admin || this.roleEnabled.manager)
  }

  get authorsVisibility (): boolean {
	  /* TODO:killerFeature */
	  let username = localStorage.getItem('username')
	  if (username === 'method') {
		  return false
	  }
    return (this.roleEnabled.admin || this.roleEnabled.methodist)
  }

  get adminVisibility (): boolean {
    return this.roleEnabled.admin
  }

  get constructorVisibility (): boolean {
    return this.roleEnabled.admin || this.roleEnabled.construct
  }

  private hideInterfaceElementsByRoleString () {
    let rolesString = localStorage.getItem('user_role')
    for (let role in Object.keys(this.roleEnabled)) {
      if (Object.hasOwnProperty(role)) {
        this.roleEnabled[role] = false
      }
    }
    if (rolesString) {
      let roles = rolesString.split(',')
      if (roles && Array.isArray(roles)) {
        for (let i = 0; i < roles.length; i++) {
          if (this.roleEnabled[roles[i]] === false) {
            Vue.set(this.roleEnabled, roles[i],true)
          }
        }
      }
    }
  }
}
